<template>
    <div>
        <v-container>
            <v-row class="mt-5">
                <v-col cols="6" md="2" class="text-end" color="white">
                    <h5 class="mt-3">{{carga_titulo}}</h5>
                </v-col>
                <v-col cols="6" md="4">
                    <v-btn class="mr-2" text fab small>
                        <v-icon class="mt-2" @click="verDetalle(0)" large>view_module</v-icon>
                    </v-btn>
                    <v-btn class="mr-2" text fab small>
                        <v-icon class="mt-2" @click="verDetalle(1)" large>view_list</v-icon>
                    </v-btn>
                </v-col>
                <v-col v-if="detail!=3" cols="9" md="4" class="text-end">
                    <v-text-field prepend-inner-icon="search" class="text-caption" v-model="search" placeholder="Buscar" outlined dense rounded clearable></v-text-field>
                </v-col>
                <v-col v-if="detail!=3" cols="3" md="2">
                    <v-btn @click="buscarProducto()" class="mt-1 text-caption" color="purple" small dark rounded>
                        <h5>Buscar</h5>
                    </v-btn>
                </v-col>
            </v-row>
            <div v-if="carga!=true">
                <div id="centra-full-screen" class="hidden-sm-and-down"> <!--full screen-->
                    <v-img src="@/assets/LogoGrum_00.jpeg" max-height="100" max-width="274"></v-img>
                    <v-progress-linear class="mt-5" indeterminate color="purple"></v-progress-linear>
                </div>
                <div id="centra-mobile-screen" class="hidden-sm-and-up"> <!--mobile screen-->
                    <v-img src="@/assets/LogoGrum_00.jpeg" max-height="50" max-width="137"></v-img>
                    <v-progress-linear class="mt-5" indeterminate color="purple"></v-progress-linear>
                </div>
            </div>
            <div v-else>
                <div v-if="detail==0">
                    <v-row class="mt-n5">
                        <v-col v-for="(item,i) in products" :key="i" cols="12" md="3" class="d-flex flex-column align-center">
                            <v-card width="250" rounded>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <div style="cursor:pointer;" @click="verItem(item)" class="d-flex flex-column align-center">
                                                <v-img :src="item.imagenes[0]" width="100" height="150"></v-img>
                                            </div>
                                            <h6 class="grey--text">SKU {{item.sku}}</h6>
                                            <h5 class="grey--text">{{item.producto}}</h5>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <h4 class="grey--text">{{item.marca}}</h4>
                                            <h6 class="grey--text">{{item.caracteristica}}</h6>
                                            <h6 class="grey--text">Stock {{item.stock}}  {{ item.medida }}</h6>
                                        </v-col>
                                        <v-col v-if="item.campania=='si'" cols="12" md="6" class="mt-5 text-end">
                                            <h5 class="grey--text">Antes</h5>
                                            <h5 class="text-decoration-line-through">${{new Intl.NumberFormat("de-DE").format(parseFloat(item.noprecio))}}</h5>
                                        </v-col>  
                                    </v-row>
                                    <v-divider class="mt-2 mb-4"></v-divider>
                                    <v-row>
                                        <v-col cols="6" md="6">
                                            <h3>${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</h3>
                                        </v-col>
                                        <v-col cols="6" md="6" class="text-center">
                                            <v-btn @click="AgregarCarrito(item)" small rounded color="purple" dark class="text-caption">
                                                <h5>agregar</h5>
                                                <v-icon class="ml-2" small>shopping_cart</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>                    
                </div>
                <div v-if="detail==1">
                    <div v-for="(item,i) in products" :key="i">
                        <v-card class="mt-10">
                            <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <div class="d-flex flex-column align-center">
                                                <v-img :src="item.imagenBase" width="300" height="300"></v-img>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="12" class="text-center">
                                            <span v-for="(img,i) in item.imagenes" :key="i">
                                                <img @click="cambiarImagen(item,i)" :src="img" class="ml-2" width="50" height="50">
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <h6>SKU {{item.sku}}</h6>
                                            <h4>{{item.producto}}</h4>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h4>{{item.marca}}</h4>
                                            <h6>{{item.caracteristica}}</h6>
                                            <h6>Stock {{item.stock}}  {{ item.medida }}</h6>
                                        </v-col>                                        
                                        <v-col cols="12" md="12">
                                            <h2>${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</h2>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-btn @click="AgregarCarrito(item)" rounded color="purple" dark block>
                                                <h5>agregar</h5>
                                                <v-icon class="ml-2">shopping_cart</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h6>Descripción</h6>
                                            <p>{{item.descripcion}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card>
                    </div>                    
                </div>
                <div v-if="detail==3">
                    <div v-for="(item,i) in producto" :key="i">
                        <v-card class="mt-10">
                            <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <div class="d-flex flex-column align-center">
                                                <v-img :src="item.imagenBase" width="300" height="300"></v-img>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="12" class="text-center">
                                            <span v-for="(img,i) in item.imagenes" :key="i">
                                                <img @click="cambiarImagen(item,i)" :src="img" class="ml-2" width="50" height="50">
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <h6>SKU {{item.sku}}</h6>
                                            <h4>{{item.producto}}</h4>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h4>{{item.marca}}</h4>
                                            <h6>{{item.caracteristica}}</h6>
                                            <h6>Stock {{item.stock}} {{ item.medida }}</h6>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h2>${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</h2>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-btn @click="AgregarCarrito(item)" rounded color="purple" dark block>
                                                <h5>agregar</h5>
                                                <v-icon class="ml-2">shopping_cart</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h6>Descripción</h6>
                                            <p>{{item.descripcion}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card>
                    </div>                    
                </div>
                <!-- productos buscados -->
                <div v-if="detail==4">
                    <v-row class="mt-n5">
                        <v-col v-for="(item,i) in products_search" :key="i" cols="12" md="3" class="d-flex flex-column align-center">
                            <v-card width="250" rounded>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <div style="cursor:pointer;" @click="verItem(item)" class="d-flex flex-column align-center">
                                                <v-img :src="item.imagenes[0]" width="100" height="150"></v-img>
                                            </div>
                                            <h6 class="grey--text">SKU {{item.sku}}</h6>
                                            <h5 class="grey--text">{{item.producto}}</h5>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <h4 class="grey--text">{{item.marca}}</h4>
                                            <h6 class="grey--text">{{item.caracteristica}}</h6>
                                            <h6 class="grey--text">Stock {{item.stock}}  {{ item.medida }}</h6>
                                        </v-col>
                                        <v-col v-if="item.campania=='si'" cols="12" md="6" class="mt-5 text-end">
                                            <h5 class="grey--text">Antes</h5>
                                            <h5 class="text-decoration-line-through">${{new Intl.NumberFormat("de-DE").format(parseFloat(item.noprecio))}}</h5>
                                        </v-col>  
                                    </v-row>
                                    <v-divider class="mt-2 mb-4"></v-divider>
                                    <v-row>
                                        <v-col cols="6" md="6">
                                            <h3>${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</h3>
                                        </v-col>
                                        <v-col cols="6" md="6" class="text-center">
                                            <v-btn @click="AgregarCarrito(item)" small rounded color="purple" dark class="text-caption">
                                                <h5>agregar</h5>
                                                <v-icon class="ml-2" small>shopping_cart</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>                    
                </div>
                <div v-if="detail==5">
                    <div v-for="(item,i) in products_search" :key="i">
                        <v-card class="mt-10">
                            <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <div class="d-flex flex-column align-center">
                                                <v-img :src="item.imagenBase" width="300" height="300"></v-img>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="12" class="text-center">
                                            <span v-for="(img,i) in item.imagenes" :key="i">
                                                <img @click="cambiarImagen(item,i)" :src="img" class="ml-2" width="50" height="50">
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <h6>SKU {{item.sku}}</h6>
                                            <h4>{{item.producto}}</h4>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h4>{{item.marca}}</h4>
                                            <h6>{{item.caracteristica}}</h6>
                                            <h6>Stock {{item.stock}} {{ item.medida }}</h6>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h2>${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</h2>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-btn @click="AgregarCarrito(item)" rounded color="purple" dark block>
                                                <h5>agregar</h5>
                                                <v-icon class="ml-2">shopping_cart</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <h6>Descripción</h6>
                                            <p>{{item.descripcion}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card>
                    </div>                    
                </div>

            </div>
        </v-container>
        <div style="height:65px"></div>
        <v-snackbar v-model="snack" timeout="2000" color="orange" shaped>
            <h4 class="brown--text">Producto agregado</h4>
        </v-snackbar>
    </div>
</template>
<style scoped>
#centra-full-screen{
  display: inline-block;
  /* vertical-align: 90%; */
  margin-top: 5%;
  margin-left:35%;
}
#centra-mobile-screen{
  display: inline-block;
  /* vertical-align: 90%; */
  margin-top: 5%;
  margin-left:30%;
}
</style>
<script>
export default {
    name:'Body',
    props:['contrato','productos','categoria'],
    data: () => ({
        titulo:'',
        snack:false,
        products: [],
        detail:0,
        search:'',
        producto:[],
        filtro:[],
        carga:false,
        products_search:[],
        searching:false
    }),
    computed:{
        carga_titulo(){
            this.titulo=this.categoria;
            this.process_products();
            return this.titulo
        }
    },
    mounted(){
        this.process_products();
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.snack=false},2000)
        },
        async verDetalle(n){
            this.search='';
            await this.process_products();
            this.detail=n
        },
        verItem(item){
            this.producto=[];
            this.producto.push(item);
            this.detail=3
        },
        async process_products(){
            this.carga=false;
            if(this.productos.lenght!=0 && this.contrato.trim()!=''){
                this.products=[];
                this.filtro = this.productos.filter( res => res.CategoriaName == this.categoria );
                this.filtro.forEach(element => {
                    var images=element.Imagenes.split(',');
                    var arrayprice=[];
                    if(element.Detalle=='si'){ arrayprice.push(parseFloat(element.PrecioLocal)) };
                    if(element.Mayor=='si'){ arrayprice.push(parseFloat(element.PrecioMayor)) };
                    if(element.Oferta=='si'){ arrayprice.push(parseFloat(element.PrecioOferta)) };
                    var price=0;
                    if(element.Detalle=='si'){ 
                        price=element.PrecioLocal;
                    } else { 
                        if(element.Mayor=='si'){ 
                            price=element.PrecioMayor;
                        } else { 
                            if(element.Oferta=='si'){
                                price=element.PrecioOferta;
                            }
                        } 
                    };
                    const maxprice=Math.max(...arrayprice);
                    const minprice=Math.min(...arrayprice);
                    if(price!=0){
                        if(element.Campania!='si'){
                            this.products.push({
                                sku:element.Sku,
                                producto:element.Nombre,
                                marca:element.Marca,
                                caracteristica:element.Caracteristica,
                                descripcion:element.Descripcion,
                                precio:maxprice,
                                campania:'no',
                                noprecio:0,
                                imagenBase:images[0],
                                imagenes:images,
                                stock:parseInt(element.Total).toFixed(0),
                                bodega:element.Bodega,
                                medida:element.Medida
                            });
                        } else {
                            this.products.push({
                                sku:element.Sku,
                                producto:element.Nombre,
                                marca:element.Marca,
                                caracteristica:element.Caracteristica,
                                descripcion:element.Descripcion,
                                precio:minprice,
                                campania:'si',
                                noprecio:maxprice,
                                imagenBase:images[0],
                                imagenes:images,
                                stock:parseInt(element.Total).toFixed(0),
                                bodega:element.Bodega,
                                medida:element.Medida
                            });
                        }
                    }
                });
            } else {
                console.log('sin datos')
            }
            this.carga=true
        },
        cambiarImagen(item,index){
            item.imagenBase=item.imagenes[index]
        },
        AgregarCarrito(item){
            var arrayCarrito={
                code:item.sku,
                src:item.imagenes[0],
                name:item.producto,
                variant:item.caracteristica,
                price:item.precio,
                quanty:1,
                store:item.bodega,
                stock:item.stock,
                medida:item.medida
            }
            this.$store.state.item_carrito = arrayCarrito;
            this.$store.dispatch('addCarritoAction');
            //console.log(this.$store.state.carrito);
            this.snack=true;
            this.showSnack();
        },
        async buscarProducto(){
            this.carga=false;
            await this.process_products();
            if(this.products.length!=0 && this.search.trim()!=''){
                var _string='';
                var arraySearch=[];
                this.products.forEach(element => {
                    //por Nombre
                    _string=element.producto.toUpperCase();
                    if(_string.includes(this.search.toUpperCase())){
                        arraySearch.push(element)
                    }else {
                        //por marca
                        _string=element.marca.toUpperCase();
                        if(_string.includes(this.search.toUpperCase())){
                            arraySearch.push(element)
                        } else {
                            //por descripcion
                            _string=element.descripcion.toUpperCase();
                            if(_string.includes(this.search.toUpperCase())){
                                arraySearch.push(element)
                            }
                        }   
                    }
                });
                if(arraySearch.length!=0){
                    this.products_search=[];
                    this.products_search=arraySearch;
                    if(this.detail==0){
                        this.detail=4
                    } else {
                        if(this.detail==1){
                            this.detail=5
                        }
                    }
                }
            }
            this.carga=true
        },

    }
}
</script>